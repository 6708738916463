import React, { useEffect, useState } from 'react';
import { Table } from 'antd';
import { ColumnsType } from 'antd/es/table';
import axios from 'axios';
import { useFetch } from '../../../shared/hooks/useFetch';

interface Workshop {
  id: number;
  title: string;
  slug: string;
  free: boolean;
  price: string;
}

interface User {
  id: number;
  first_name: string;
  last_name: string;
  email: string;
}

interface UserWorkshop {
  id: number;
  workshop: Workshop;
  user: User;
}

export const TablePaidWorkshops: React.FC = () => {
  const columns: ColumnsType<UserWorkshop> = [
    {
      title: 'ID',
      dataIndex: ['user', 'id'],
      key: 'userId',
      sorter: (a, b) => a.user.id - b.user.id,
    },
    {
      title: 'Usuario',
      key: 'userName',
      render: (_, record) => `${record.user.first_name} ${record.user.last_name}`,
      sorter: (a, b) => `${a.user.first_name} ${a.user.last_name}`.localeCompare(`${b.user.first_name} ${b.user.last_name}`),
    },
    {
      title: 'Email',
      dataIndex: ['user', 'email'],
      key: 'email',
      sorter: (a, b) => a.user.email.localeCompare(b.user.email),
    },
    {
      title: 'Curso',
      dataIndex: ['workshop', 'title'],
      key: 'workshop',
      sorter: (a, b) => a.workshop.title.localeCompare(b.workshop.title),
    },
  ];

  const { data, loading, error, fetch } = useFetch<UserWorkshop[]>();

  useEffect(() => {
    fetch("/users/users-with-workshops");
  }, [fetch]);

  const filteredPaidData = data?.filter((item: UserWorkshop) => !item.workshop.free) || [];

  return (
    <Table
      columns={columns}
      dataSource={filteredPaidData}
      loading={loading}
      rowKey="id"
      pagination={{
        pageSize: 10,
        showSizeChanger: true,
        showTotal: (total) => `Total ${total} registros`,
      }}
    />
  );
};