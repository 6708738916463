import { TableAllUsers } from '../modules/allUsers'
import { AdminLayout } from '../shared'

export function AllUsersPage() {
  return (
    <AdminLayout>
      <TableAllUsers />
    </AdminLayout>
  )
}
