import { useState, useCallback } from "react";

const BASE_URL = process.env.REACT_APP_NEW_API_URL;

type HttpMethod = "GET" | "POST" | "PUT" | "DELETE";

interface FetchOptions {
  method?: HttpMethod;
  headers?: HeadersInit;
  body?: any;
}

interface UseFetchResult<T> {
  data: T | null;
  loading: boolean;
  error: Error | null;
  fetch: (endpoint: string, options?: FetchOptions) => Promise<void>;
}

export function useFetch<T = any>(): UseFetchResult<T> {
  const [data, setData] = useState<T | null>(null);
  const [loading, setLoading] = useState<boolean>(false);
  const [error, setError] = useState<Error | null>(null);

  const fetchData = useCallback(
    async (endpoint: string, options: FetchOptions = {}) => {
      try {
        setLoading(true);
        setError(null);

        const { method = "GET", headers = {}, body } = options;
        const token = localStorage.getItem("jwt");

        const fetchOptions: RequestInit = {
          method,
          headers: {
            "Content-Type": "application/json",
            ...(token && { Authorization: `Bearer ${token}` }),
            ...headers,
          },
          ...(body && { body: JSON.stringify(body) }),
        };

        const response = await fetch(`${BASE_URL}${endpoint}`, fetchOptions);

        if (!response.ok) {
          throw new Error(`HTTP error! status: ${response.status}`);
        }

        const result = await response.json();
        setData(result);
      } catch (err) {
        setError(err instanceof Error ? err : new Error("An error occurred"));
      } finally {
        setLoading(false);
      }
    },
    []
  );

  return { data, loading, error, fetch: fetchData };
}
