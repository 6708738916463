import { Avatar, Typography, Tag, Tooltip, Button, message, Input, Table, Select } from "antd";
import { TableColumns } from "../../shared/components/ui/table/types";
import { TableCustom, CompanyUsersService, CompaniesService } from "../../shared";
import { DownloadOutlined, SearchOutlined } from "@ant-design/icons";
import { useState, useCallback, useEffect } from "react";

const { Title } = Typography;
const BATCH_SIZE = 1000;

// Función de delay para esperar entre lotes
const delay = (ms: number) => new Promise(resolve => setTimeout(resolve, ms));

interface Area {
  id: number;
  title: string;
  deletedAt: null | string;
}

interface CompanyUser {
  id: number;
  company_id: number;
  user_id: number;
  role: string;
  access_status: string;
  rejected_reason: string | null;
  validated_by_user_id: number | null;
  can_answer_supplier_chat: boolean | null;
  can_answer_work_offers_chat: boolean | null;
  company_users_job_title_area_id: number | null;
  company_users_job_title_area_position_id: number | null;
  is_notified: string;
  createdAt: string;
  updatedAt: string;
  company: {
    id: number;
    dni: string;
    legal_name: string;
    DIRECCION_COMERCIAL: string | null;
    NOMBRE_MUNICIPIO_DC: string | null;
    MATRICULA: string;
    is_corresponsal: string;
    is_affiliate: string;
    cluster_id: number | null;
    key_account_manager_id: number | null;
    FECHA_RENOVACION: string | null;
    cluster?: {
      id: number;
      name: string;
    };
    key_account_manager?: {
      id: number;
      first_name: string;
      last_name: string;
      email: string;
      phone: string;
    };
  };
  user: {
    id: number;
    first_name: string;
    last_name: string | null;
    email: string;
    phone: string;
  };
  company_users_job_title_area?: {
    id: number;
    title: string;
  };
  company_users_job_title_area_position?: {
    id: number;
    title: string;
  };
}

interface CsvRow {
  'ID Usuario': number;
  'Nombre Usuario': string;
  'Email': string;
  'Teléfono': string;
  'Empresa': string;
  'NIT': string;
  'Matrícula': string;
  'Rol': string;
  'Área': string;
  'Cargo': string;
  'Estado': string;
  'Fecha Creación': string;
  'Última Actualización': string;
}

interface CompanyDetails {
  id: number;
  dni: string;
  MATRICULA: string;
  legal_name: string;
  status: string;
  is_corresponsal: string;
  is_affiliate: string;
  key_account_manager_id: number | null;
  cluster_id: number | null;
  CATEGORIA: string;
  FECHA_RENOVACION: string;
  CIIU_PRINCIPAL: string;
  DESCRIPCION_CIIU: string;
  TAMANO: string;
  DIRECCION_COMERCIAL: string;
  NOMBRE_MUNICIPIO_DC: string;
  NOMBRE_REP_LEGAL: string;
  TAMANO_INGRESOS: string;
  CORREO_ELECTRONICO_DC: string;
  CARGO_REP_LEGAL: string;
  TOTAL_ACTIVO: number;
  NRO_EMPLEADOS: number;
  TELEFONO_DC: string;
  TELEFONO_DJ: string;
  TELEFONO_DCOR: string;
}

export function TableAllUsers() {
  const [downloading, setDownloading] = useState(false);
  const [searchText, setSearchText] = useState('');
  const [selectedArea, setSelectedArea] = useState<number | null>(null);
  const [areas, setAreas] = useState<Area[]>([]);
  const [tableKey, setTableKey] = useState(0);
  const [affiliateStatus, setAffiliateStatus] = useState<Record<number, string | null>>({});
  const [failedRequests, setFailedRequests] = useState<Set<number>>(new Set());
  const [companyData, setCompanyData] = useState<CompanyUser[] | null>(null);
  const [loading, setLoading] = useState(false);

  // Cargar áreas al inicio
  useEffect(() => {
    const fetchAreas = async () => {
      try {
        const response = await fetch('https://tpwwxsbrxu.us-east-1.awsapprunner.com/company-users-job-title-area/');
        const data = await response.json();
        setAreas(data.data);
      } catch (error) {
        console.error('Error fetching areas:', error);
        message.error('Error al cargar las áreas');
      }
    };
    fetchAreas();
  }, []);

  const handleSearch = async () => {
    if (!searchText.trim()) {
      message.warning('Por favor ingrese un ID de empresa');
      return;
    }

    setLoading(true);
    setCompanyData(null);

    try {
      const response = await CompanyUsersService.find({
        query: {
          company_id: searchText.trim()
        }
      });

      if (response.data && response.data.length > 0) {
        setCompanyData(response.data);
      } else {
        message.info('No se encontraron resultados');
        setCompanyData([]);
      }
    } catch (error) {
      console.error('Error searching:', error);
      message.error('Error al buscar usuarios');
    } finally {
      setLoading(false);
    }
  };

  const handleAreaChange = (value: number | null) => {
    setSelectedArea(value);
    setCompanyData(null); // Limpiar resultados de búsqueda si hay
    setSearchText(''); // Limpiar búsqueda por ID
  };

  const handleClearSearch = () => {
    setSearchText('');
    setCompanyData(null);
  };

  const downloadAllUsers = async () => {
    setDownloading(true);
    try {
      // Primero obtener el total de registros
      const countQuery: any = {
        $limit: 0,
        $skip: 0
      };
      
      if (selectedArea) {
        countQuery.company_users_job_title_area_id = selectedArea;
      }

      const countResponse = await CompanyUsersService.find({
        query: countQuery
      });

      const total = countResponse.total;
      console.log('Total de usuarios:', total);
      
      if (!total) {
        message.error('No se pudo obtener el total de registros');
        return;
      }

      const batches = Math.ceil(total / BATCH_SIZE);
      console.log('Número de lotes a descargar:', batches);
      
      let allUsers: CompanyUser[] = [];
      let retryCount = 0;
      const MAX_RETRIES = 3;

      // Mostrar mensaje inicial
      message.loading({ content: `Iniciando descarga de ${total} registros...`, key: 'download' });

      // Obtener datos en lotes
      for (let i = 0; i < batches; i++) {
        message.loading({
          content: `Descargando lote ${i + 1} de ${batches} (${allUsers.length} de ${total} registros)...`,
          key: 'download'
        });

        let success = false;
        while (!success && retryCount < MAX_RETRIES) {
          try {
            const batchQuery: any = {
              $limit: BATCH_SIZE,
              $skip: i * BATCH_SIZE
            };

            if (selectedArea) {
              batchQuery.company_users_job_title_area_id = selectedArea;
            }

            const response = await CompanyUsersService.find({
              query: batchQuery
            });

            if (response.data && response.data.length > 0) {
              allUsers = [...allUsers, ...response.data];
              console.log(`Lote ${i + 1}: ${response.data.length} registros. Total acumulado: ${allUsers.length}`);
              success = true;
              retryCount = 0;
            } else {
              console.log(`Lote ${i + 1}: sin datos`);
              break;
            }
          } catch (error) {
            console.error(`Error en lote ${i + 1}, intento ${retryCount + 1}:`, error);
            retryCount++;
            if (retryCount < MAX_RETRIES) {
              message.warning(`Reintentando lote ${i + 1}...`);
              await delay(2000);
            } else {
              message.error(`Error descargando lote ${i + 1} después de ${MAX_RETRIES} intentos`);
              break;
            }
          }
        }

        if (success) {
          await delay(500);
        }
      }

      // Preparar los datos para CSV
      const headers = [
        'ID',
        'ID Empresa',
        'Usuario',
        'Email',
        'Teléfono',
        'Empresa',
        'NIT',
        'Matrícula',
        'Rol',
        'Área',
        'Cargo',
        'Estado',
        'Fecha Creación',
        'Última Actualización'
      ];

      // Función para escapar valores CSV
      const escapeCsvValue = (value: string | number) => {
        if (value === null || value === undefined) return '';
        const stringValue = String(value);
        const needsQuotes = stringValue.includes(',') || stringValue.includes('"') || stringValue.includes('\n');
        if (!needsQuotes) return stringValue;
        return `"${stringValue.replace(/"/g, '""')}"`;
      };

      const rows = allUsers.map(user => [
        user.id || '',
        user.company_id || '',
        `${user.user?.first_name || ''} ${user.user?.last_name || ''}`.trim(),
        user.user?.email || '',
        user.user?.phone || '',
        user.company?.legal_name || '',
        user.company?.dni || '',
        user.company?.MATRICULA || '',
        user.role || '',
        user.company_users_job_title_area?.title || '',
        user.company_users_job_title_area_position?.title || '',
        user.access_status === 'active' ? 'Activo' : 'Inactivo',
        new Date(user.createdAt).toLocaleDateString(),
        new Date(user.updatedAt).toLocaleDateString()
      ].map(escapeCsvValue));

      // Crear el contenido CSV
      const csvContent = [
        headers.join(','),
        ...rows.map(row => row.join(','))
      ].join('\n');

      // Crear y descargar el archivo
      const blob = new Blob(['\ufeff' + csvContent], { type: 'text/csv;charset=utf-8;' });
      const link = document.createElement('a');
      const url = URL.createObjectURL(blob);
      link.setAttribute('href', url);
      link.setAttribute('download', `usuarios_empresas_${new Date().toISOString().split('T')[0]}_${allUsers.length}_registros.csv`);
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
      URL.revokeObjectURL(url);
      
      message.success({
        content: `Se descargaron ${allUsers.length} de ${total} registros exitosamente`,
        key: 'download'
      });
    } catch (error) {
      console.error('Error downloading users:', error);
      message.error({
        content: 'Error al descargar los usuarios',
        key: 'download'
      });
    } finally {
      setDownloading(false);
    }
  };

  const handleKeyPress = (e: React.KeyboardEvent<HTMLInputElement>) => {
    if (e.key === 'Enter') {
      handleSearch();
    }
  };

  const fetchAffiliateStatus = useCallback(async (companyId: number) => {
    try {
      // Si ya falló antes o ya tenemos el estado, no intentar de nuevo
      if (failedRequests.has(companyId) || affiliateStatus[companyId] !== undefined) {
        return affiliateStatus[companyId];
      }

      // Crear un timeout de 5 segundos
      const timeoutPromise = new Promise((_, reject) => {
        setTimeout(() => reject(new Error('Timeout')), 5000);
      });

      // Hacer la petición con timeout
      const response = await Promise.race([
        CompaniesService.get(companyId),
        timeoutPromise
      ]);

      setAffiliateStatus(prev => ({
        ...prev,
        [companyId]: response.is_affiliate
      }));
      return response.is_affiliate;
    } catch (error) {
      console.error(`Error fetching affiliate status for company ${companyId}:`, error);
      // Marcar como fallida para no intentar de nuevo
      setFailedRequests(prev => new Set([...Array.from(prev), companyId]));
      setAffiliateStatus(prev => ({
        ...prev,
        [companyId]: null
      }));
      return null;
    }
  }, [affiliateStatus, failedRequests]);

  const columns: TableColumns<CompanyUser> = [
    {
      title: "ID Empresa",
      dataIndex: ["company", "id"],
      align: "left",
      width: 100,
      render: (_: unknown, record: CompanyUser) => record?.company?.id || '-'
    },
    {
      title: "Usuario",
      dataIndex: ["user"],
      align: "left",
      render: (user: CompanyUser['user']) => {
        if (!user) return '-';
        return (
          <div style={{ display: 'flex', alignItems: 'center', gap: '10px' }}>
            <Avatar>{user.first_name?.[0] || '?'}</Avatar>
            {`${user.first_name || ''} ${user.last_name || ''}`.trim() || '-'}
          </div>
        );
      },
    },
    {
      title: "Email",
      dataIndex: ["user", "email"],
      align: "left",
      render: (_: unknown, record: CompanyUser) => record?.user?.email || '-'
    },
    {
      title: "Empresa",
      dataIndex: ["company", "legal_name"],
      align: "left",
      render: (_: unknown, record: CompanyUser) => record?.company?.legal_name || '-'
    },
    {
      title: "NIT",
      dataIndex: ["company", "dni"],
      align: "left",
      render: (_: unknown, record: CompanyUser) => record?.company?.dni || '-'
    },
    {
      title: "Matrícula",
      dataIndex: ["company", "MATRICULA"],
      align: "left",
      render: (_: unknown, record: CompanyUser) => record?.company?.MATRICULA || '-'
    },
    {
      title: "Afiliado",
      align: "center",
      render: (_: unknown, record: CompanyUser) => {
        const isAffiliate = record?.company?.is_affiliate;
        return (
          <Tag color={isAffiliate === 'true' ? 'green' : 'default'}>
            {isAffiliate === 'true' ? 'Sí' : 'No'}
          </Tag>
        );
      }
    },
    {
      title: "Rol",
      dataIndex: "role",
      align: "left",
      render: (role: string, record: CompanyUser) => record?.role ? <Tag color="blue">{record.role}</Tag> : '-',
    },
    {
      title: "Área y Cargo",
      align: "left",
      render: (_: unknown, record: CompanyUser) => {
        const area = record?.company_users_job_title_area;
        const position = record?.company_users_job_title_area_position;
        
        if (!area && !position) return '-';
        
        return (
          <div style={{ display: 'flex', flexDirection: 'column', gap: '4px' }}>
            {area && <Tag color="purple">{area.title}</Tag>}
            {position && <Tag color="cyan">{position.title}</Tag>}
          </div>
        );
      },
    },
    {
      title: "Estado",
      dataIndex: "access_status",
      align: "left",
      render: (status: string, record: CompanyUser) => (
        <Tag color={record?.access_status === 'active' ? 'green' : 'red'}>
          {record?.access_status === 'active' ? 'Activo' : 'Inactivo'}
        </Tag>
      ),
    }
  ];

  return (
    <div style={{ padding: "20px" }}>
      <Title level={4}>Usuarios de Empresas</Title>
      <div style={{ marginBottom: 16, display: 'flex', gap: '16px' }}>
        <Input
          placeholder="Buscar por ID de empresa"
          value={searchText}
          onChange={(e) => setSearchText(e.target.value)}
          onKeyPress={handleKeyPress}
          style={{ width: 200 }}
          prefix={<SearchOutlined />}
          allowClear
          onPressEnter={handleSearch}
        />
        <Select
          style={{ width: 200 }}
          placeholder="Filtrar por área"
          allowClear
          value={selectedArea}
          onChange={handleAreaChange}
          options={areas.map(area => ({
            value: area.id,
            label: area.title
          }))}
        />
        <Button
          type="primary"
          icon={<SearchOutlined />}
          onClick={handleSearch}
          loading={loading}
          disabled={!searchText.trim()}
        >
          Buscar por ID
        </Button>
        {companyData !== null && (
          <Button onClick={handleClearSearch}>
            Limpiar búsqueda
          </Button>
        )}
        <Button
          type="default"
          icon={<DownloadOutlined />}
          onClick={downloadAllUsers}
          loading={downloading}
        >
          Descargar {selectedArea ? 'Filtrados' : 'Todos'}
        </Button>
      </div>

      {companyData !== null ? (
        <Table
          columns={columns}
          dataSource={companyData}
          rowKey="id"
          pagination={false}
        />
      ) : (
        <TableCustom
          key={tableKey}
          columns={columns}
          service={CompanyUsersService}
          fetch$ClientProps={selectedArea ? {
            company_users_job_title_area_id: selectedArea
          } : undefined}
        />
      )}
    </div>
  );
}
