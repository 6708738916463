import React from 'react';
import { Tabs } from 'antd';
import type { TabsProps } from 'antd';
import { TableInscriptions } from './TableInscriptions';
import { TableWebinars } from './TableWebinars';
import { TableEvents } from './TableEvents';
import { TableProjets } from './TableProjets';
import { TablePaidWorkshops } from './TablePaidWorkshops';
import { TableFreeWorkshops } from './TableFreeWorkshops';

const onChange = (key: string) => {
  console.log(key);
};

const items: TabsProps['items'] = [
  {
    key: '1',
    label: 'Cursos',
    children: <TableInscriptions/>,
  },
  {
    key: '2',
    label: 'Webinars',
    children: <TableWebinars />,
  },
  {
    key: '3',
    label: 'Eventos',
    children: <TableEvents/>,
  },
  {
    key: '4',
    label: 'Proyectos',
    children: <TableProjets/>,
  },
    {
    key: '5',
    label: 'Contenidos Digitales Pagos',
    children: <TablePaidWorkshops/>,
  },
  {
    key: '6',
    label: 'Contenidos Digitales Gratis',
    children: <TableFreeWorkshops/>,
  },
];

const Inscriptions: React.FC = () => (
    <>
        <Tabs style={{width: '100%'}} defaultActiveKey="1" items={items} onChange={onChange} />
    </>
);

export default Inscriptions;